




























import {Component, Vue} from 'vue-property-decorator'

@Component({})
export default class Conclusion extends Vue {
  private tableData = [
    {
      sport: true,
      healthy: true
    },
    {
      sport: true,
      healthy: false
    },
    {
      sport: false,
      healthy: true
    },
    {
      sport: false,
      healthy: false
    }
  ]
}
